<template>
  <router-view></router-view>
  <h1>Base Price</h1>
  <hr/>
  <bs-error-message :errors="errorMessages"/>

  <div class="row justify-content-between">
    <div class="col-md-6">
      <div class="d-grid gap-3 d-md-block">
        <router-link to="/base-price/generate" class="btn btn-primary btn-sm me-md-1">
          <i class="bi bi-file-earmark"></i> Generate Base Price
        </router-link>
      </div>
    </div>
    <div class="col-md-6">
      <div class="pagination-right">
        <bs-pagination :pageNumber="pageNumber" :perPage="perPage" :totalPage="totalPage" :totalRow="totalRow"
                       @toPage="toPage"/>
      </div>
    </div>
  </div>

  <div class="">
    <table class="table table-striped">
      <thead>
      <tr>
        <th rowspan="2">
          Action
        </th>
        <th>
          <bs-ordering property="nama" @reorder="reorder">Nama</bs-ordering>
        </th>
        <th>
          <bs-ordering property="quantity" @reorder="reorder">Qty</bs-ordering>
        </th>
        <th>
          <bs-plain-text :model-value="`Currency/Unit`"/>
        </th>
        <th>
          <bs-ordering property="status" @reorder="reorder">Status</bs-ordering>
        </th>
        <th>
          <bs-ordering property="remarks" @reorder="reorder">Remarks</bs-ordering>
        </th>
        <th>
          <bs-ordering property="validityStart" @reorder="reorder">Validity Start</bs-ordering>
        </th>
        <th>
          <bs-ordering property="validityEnd" @reorder="reorder">Validity End</bs-ordering>
        </th>
      </tr>
      <tr>
        <th>
          <div>
            <bs-textbox placeholder="Nama" v-model="filter.ProfileNameContain" @keyup="search()"/>
          </div>
        </th>
        <th>
          <div>
            <bs-textbox type="number" placeholder="Quantity" v-model="filter.QuantityEquals" @keyup="search()"/>
          </div>
        </th>
        <th>
          <div>
          </div>
        </th>
        <th>
          <div>
            <status-combo v-model="filter.StatusEquals" :text-default="filter.StatusEqualsName" @update:model-value="search()"/>
          </div>
        </th>
        <th>
          <div>
            <bs-textbox placeholder="Remarks" v-model="filter.RemarksContains" @keyup="search()"/>
          </div>
        </th>
        <th>
          <div>
            <bs-date-picker v-model="filter.ValidFromFrom" placeholder="Valid From From" @change="search()" />
            <bs-date-picker class="mt-1" v-model="filter.ValidFromUntil" placeholder="Valid From To" @change="search()" />
          </div>
        </th>
        <th>
          <div>
            <bs-date-picker v-model="filter.ValidUntilFrom" placeholder="Valid To From" @change="search()" />
            <bs-date-picker class="mt-1" v-model="filter.ValidUntilUntil" placeholder="Valid To Until" @change="search()" />
          </div>
        </th>
      </tr>
      </thead>
      <tbody>
      <template v-if="data != null && data.length > 0">
        <tr v-for="item in data" :key="item.id">
          <td>
            <div class="btn-group">
              <button class="btn btn-sm btn-secondary dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                <i class="bi bi-menu-down"></i> Action
              </button>
              <ul class="dropdown-menu">
                <li>
                  <button @click="detailItem(item)" class="dropdown-item"><i class="bi bi-eye"></i> View Details
                  </button>
                </li>
                <li>
                  <button @click="editItem(item)" class="dropdown-item"><i class="bi bi-pencil-square"></i> Edit
                  </button>
                </li>
                <li>
                    <button @click="deleteGroup(item)" class="dropdown-item"><i class="bi bi-trash"></i> Delete</button>
                </li>
              </ul>
            </div>
          </td>
          <td>
            {{ item.profilNama }}
          </td>

          <td>
            {{ item.quantity }}
          </td>

          <td>
            {{ item.currencyUnitLabel }}
          </td>

          <td>
            {{ item.statusNama }}
          </td>

          <td>
            {{ item.remarks }}
          </td>

          <td>
            <view-date v-model="item.validityStart"/>
          </td>

          <td>
            <view-date v-model="item.validityEnd"/>
          </td>
        </tr>
      </template>
      </tbody>
    </table>
  </div>
</template>

<script>
import BasePriceService from "@/services/base-price-service";
import ViewDate from "@/components/ViewDate.vue";
import StatusCombo from "@/components/StatusCombo.vue";
import {getNamaBulan} from "@/plugins/helper";
// import SecurityService from "@/services/security-service.js";

export default {
  components: {StatusCombo, ViewDate },
  data: function () {
    return {
      filter: {
        ValidFromFrom:null,
        ValidFromUntil:null,
        ValidUntilFrom:null,
        ValidUntilUntil:null,
        ProfileNameContain:'',
        RemarksContains:'',
        QuantityEquals:0,
        StatusEquals:null,
        StatusEqualsName:null,
        page: 1,
        perPage: 10,
        orderByProperty: 'id',
        orderType: 0
      },
      errorMessages: null,
      pageNumber: 1,
      perPage: 10,
      totalPage: 100,
      totalRow: 0,
      data: Array(),
      isAuthCreate: false,
      isAuthEdit: false
    }
  },
  watch: {
    $route: function () {
      if (this.$route.path == "/base-price") {
        this.loadData();
      }
    }
  },
  mounted() {
    this.loadData();
  },
  methods: {
    async toPage(pageNumber) {
      this.filter.page = pageNumber;
      this.loadData();
    },
    async reorder(property, orderType) {
      this.filter.orderByProperty = property;
      this.filter.orderType = orderType;
      this.loadData();
    },
    async search() {
      this.filter.page = 1;
      this.loadData();
    },
    async loadData() {
      const result = await BasePriceService.getList(this.filter);
      if (result.status) {
        this.errorMessages = null;
      } else {
        this.errorMessages = result.errorMessages;
      }

      const data = result.data;
      this.pageNumber = data.pageNumber;
      this.perPage = data.perPage;
      this.totalPage = data.totalPage;
      this.totalRow = data.totalRow;
      this.data = data.data;
    },
    detailItem(item) {
      this.$router.push(`/base-price/detail/${item.id}`);
    },
    editItem(item) {
      this.$router.push(`/base-price/edit/${item.id}`);
    },
    async deleteItem(item) {
      if (confirm(`Are you sure want to delete ${item.profilNama} with ${item.remarks}?`)) {
        const result = await BasePriceService.delete(item.id);
        if (result.status) {
          this.loadData();
        }
      }
    },
    async deleteGroup(item) {
      const model = BasePriceService._toObjectForEdit(item);
      const idProfile = model.profilId;
      const month = model.validityStart.getMonth()+1;
      const year = model.validityStart.getFullYear();
      const namaMonth = getNamaBulan(month);
      console.log(month);
       if (confirm(`all prices on profiles ${model.profilNama} in ${namaMonth} ${year} will be deleted and can't be undone. Are you sure want to delete?`)) {
           const result = await BasePriceService.deleteGroup(idProfile,month,year);
           if (result.status) {
               this.loadData();
           }
       }
    }
  }
}
</script>

<style>

</style>